import React from "react";
import { Link } from "react-router-dom";

const Blog = props => {
  return (
    <div className="blog-list">
      <p className="sub-header">Blog?</p>
      <Link to={{ pathname: "/blog/generalist" }}>
        <p className="copy">Navigating the world as a generalist</p>
      </Link>
      <Link to={{ pathname: "/blog/waterloo" }}>
        <p className="copy">Waterloo startup ecosystem</p>
      </Link>
    </div>
  );
};

export default Blog;
