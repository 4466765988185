import React from "react";
import projects from "./../../content";
import "./project.scss";
import { useLocation } from 'react-router-dom'


import ProjectFooter from "./../../components/ProjectFooter";

const Project = props => {
  const location = useLocation();
  const project = projects.find(o => o.id === location.pathname);
  return (
    <div className="project-page-container">
      <div className="project-title h2">
        <p>{project.name}</p>
      </div>
     
      <div className="project-content">{project.content}</div>
      {ProjectFooter}
    </div>
  );
};

export default Project;
