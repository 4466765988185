import React from "react";
import "./App.scss";
import { HashRouter as Switch, Route } from "react-router-dom";
import Home from "./screens/home/index";
import Ideas from "./screens/ideas/index";
import Blog from "./screens/blog/index";

import Project from "./screens/project/index";

function App() {
  return (
    <main>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/ideas" component={Ideas} />
        <Route path="/blog" component={Blog} />

        <Route path="/blog/generalist" component={Project} />
      </Switch>
    </main>
  );
}

export default App;
