import React from "react";
import "./ProjectFooter.scss";

const ProjectFooter = props => (
  <div className="img-container">
    <img className={props.type} src={props.src} />
  </div>
);

export default ProjectFooter;
