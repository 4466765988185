import React from "react";
import HorizontalRule from "./../../components/HorizontalRule";
import BodyText from "./../../components/BodyText";
import ImgCard from "./../../components/ImgCard";

const Blog1Content = () => {
  return (
    <div>
      <HorizontalRule></HorizontalRule>
      <BodyText>Yo what up this is some body text in a para</BodyText>
    </div>
  );
};
export default Blog1Content;
