import React from "react";

const HorizontalRule = () => (
  <hr
    style={{
      color: "#BABABA",
      backgroundColor: "#BABABA",
      border: 0,
      height: 1,
      margin: "40px 0"
    }}
  />
);

export default HorizontalRule;
