import React from "react";
import Blog1Content from "./blog1"
const projects = [
  {
    id: "/blog/generalist",
    name: "Navigating the world as a generalist",
    content: <Blog1Content></Blog1Content>
  },
];

export default projects;
