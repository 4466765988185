import "./home.scss";
import React from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import img1 from "./../../assets/photos/1.jpeg";
import img2 from "./../../assets/photos/2.jpeg";
import img3 from "./../../assets/photos/3.jpeg";

const Home = () => {
  return (
    <div className="App">
      <p className="main-text">Welcome 🚀🔥📚👀 </p>
      <div className="imgs">
        <img src={img1}></img>
        <img src={img2}></img>
        <img src={img3}></img>
      </div>
      <Link to={{ pathname: "/ideas" }}>
        <p className="sub-header">Ideas</p>
      </Link>
      <Link to={{ pathname: "/blog" }}>
        <p className="sub-header">Blog?</p>
      </Link>

      <p className="sub-header">People I want to meet (coming soon)</p>


      <p className="sub-header">Timeline</p>
      <div class="bar"></div>

      <div class="timeline">
        {experiences.map((exp, i) => (
          <div class="entry">
            <h1>{exp.date}</h1>
            <h2>{exp.name}</h2>
            {/* <img src={exp.img} /> <p>{exp.desc}</p> */}
          </div>
        ))}
      </div>

      <br></br>
      <br></br>
    </div>
  );
};

export default Home;

const experiences = [
  {
    name: "Autodesk PM Internship",
    date: "2020",
    desc: "this was a good time"
  },
  {
    name: "Year Zero Studios Mentor",
    date: "2020",
    desc: "this was a good time"
  },
  {
    name: "Cognite Design/PM Internship",
    date: "2020",
    desc: "this was a good time"
  },
  {
    name: "PlanGrid Design Internship",
    date: "2019",
    desc: "this was a good time"
  },
  {
    name: "Senso Part-time Design",
    date: "2019",
    desc: "this was a good time"
  },
  {
    name: "IBM Design Internship",
    date: "2018",
    desc: "this was a good time"
  },
  {
    name: "Hack the North Design Team",
    date: "2018",
    desc: "this was a good time"
  },
  {
    name: "Polar Frontend Internship",
    date: "2018",
    desc: "this was a good time"
  },
  {
    name: "Engineering at Waterloo",
    date: "2017",
    desc: "this was a good time"
  },
  {
    name: "Senso Frontend Internship",
    date: "2017",
    desc: "this was a good time"
  },
  {
    name: "DECA ICDC - Anaheim",
    date: "2017",
    desc: "this was a good time"
  },
  {
    name: "YRAA Ultiamte Champions",
    date: "2017",
    desc: "this was a good time"
  },
  {
    name: "Student Council Treasurer",
    date: "2016",
    desc: "this was a good time"
  },
  {
    name: "Prime Leaders YR",
    date: "2014",
    desc: "this was a good time"
  },
  {
    name: "Mayor's Yout Council",
    date: "2014",
    desc: "this was a good time"
  },
  {
    name: "Joined DECA",
    date: "2013",
    desc: "this was a good time"
  },
  {
    name: "YENYR",
    date: "2013",
    desc: "this was a good time"
  },
  {
    name: "Aim to Sustain Contest",
    date: "2013",
    desc: "this was a good time"
  },
  {
    name: "Enrolled at Boxwoox",
    date: "2013",
    desc: "this was a good time"
  },
  {
    name: "Enrolled at Coppard",
    date: "2004?",
    desc: "this was a good time"
  },
  {
    name: "born",
    date: "1999",
    desc: "this was a good time"
  }
];
