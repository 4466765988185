import React from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";

const Ideas = props => {
  return (
    <div>
      <p className="sub-header">Ideas</p>
      <p className="copy">
        Collection of various business / life ideas I've had in the past.
      </p>

      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>ctrl-f audio</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Live transcripts of meetings for user interviews and sales. Good
              for lecutres as well. Since thinking about this, otter.ai has been
              popular.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>ar/vr concerts</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              vr/ar concert subscription service. Pay ~$10/month for access to
              different artists concerts every couple of days. Immersive
              experience and see artist inside your own house. Artists like
              Weeknd and Trav have done experiments w Tiktok and Fortnite.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              always on team drop in room for work sessions.
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              always on team drop in room for work sessions. Like house party
              but for businesses. tandem.chat is doing something like this.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>ai note taking app</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Drop a note into a "notes" like app that will then take an action
              for you (ex, cal event, email, doc in notion)
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>learnbox</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Robust bookmarking tool for saving content. Social aspect of
              viewing article summaries from friends you like and netflix style
              personalized inventory for saved work.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>remote games for kids</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              In post-covid world many elder relatives and grandparents are
              unable to engage with their grandkids. Rather than just video
              call, creating an engaging platform of games so they can get to
              know each other would be nice.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>relationship crm</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Hard to keep up and keep track with people you meet, both in life
              and work
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              new domain exposure for adults
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              10,000h to master, life has many sections where you can explore
              and go into different domains. People often get turned off by
              things like a bad chemistry teacher. Re-introducing fundamental
              knowledge from new domains to adults would be a way to find your
              next interest. Could be a 6 week program with diff topic every
              week.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>content queue</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              TikTok, YouTube up next, etc. validate that people like being fed
              content. Idea is to create some sort of queue which is platform
              agnostic.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>pm collaboration forum</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Bring the idea of a PR to the pm world. More of a process shift to
              allow pms to collaborate. Dribbble for pms?
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              goal and achievement network
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Beautiful visualization of your timeline and stuff you have done
              in the past. Store of descriptions of all your activities so you
              can pull in things to share with employers and tailor
              applications.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Ideas;
